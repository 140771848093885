import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  TextField,
  TextareaAutosize,
  Typography,
  Button,
} from "@mui/material";
import {
  heroImage,
  twitterIcon,
  instgramIcon,
  linkdinImage,
  arrowBlack,
  scaleKYC,
  heroArrow,
  lineImage,
  digitalImage,
  supportImage,
  readMoreArrow,
  scaleKycImage,
  globeImage,
} from "./assets";
import PhoneInput from "react-phone-input-2";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrivacyDialog from "../../../components/src/PrivacyDiaglog";
// Customizable Area End
import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderHeader = () => {
    return (
      <CustomBox className="headerBox">
        <button onClick={this.goToLoginScreen} className="loginBtn">
          {configJSON.loginText}
        </button>
        <Box>
          <img src={scaleKYC} className="headerLogo" />
        </Box>
      </CustomBox>
    );
  };

  renderContainers = () => {
    return (
      <>
        <MidBox>
          <Box className="heroLeft">
            <img src={heroImage} alt="hero" className="imageAnimate" />
            <img src={heroArrow} alt="hero arrow" className="heroArrow" />
          </Box>
          <Typography className="heroTxt">{configJSON.clickText}</Typography>
        </MidBox>
      </>
    );
  };

  renderServices = () => {
    return (
      <ServiceBox className="serviceBox">
        <Box className="serviceMidBox">
          <Box className="firstMidBox">
            <Box className="paddingBox">
              <Box>
                <img src={lineImage} className="progessImg" />
              </Box>
              <Box className="serviceMidHeading">
                <span style={{ color: "white" }}>{this.state.titleOne}</span>
                {/* <span style={{ color: "#00FF00" }}>KYC</span> */}
              </Box>
              <Box className="serviceMidPara">{this.state.discripetionOne}</Box>
            </Box>
            <Box className="imgMargin">
              <img
                className="dashboardImg"
                src={scaleKycImage}
                alt="dashboardImg"
              />
            </Box>
          </Box>
          <Box className="secMidBox">
            <Box className="whiteCard">
              <Box className="paddingBox">
                <Box>
                  <img src={digitalImage} className="progessImg" />
                </Box>
                <Box className="serviceMidHeading">
                  {this.state.titletwo}
                </Box>
                <Box className="whiteMidPara">{this.state?.discripetiontwo} </Box>
                <Button
                  variant="text"
                  color="primary"
                  href="https://www.scalecompliance.com/"
                  target="_blank"
                  className="btnstyle"
                >
                  {configJSON.readMoreText}
                  <img
                    src={readMoreArrow}
                    alt="arrow"
                    className="readMoreArrow"
                    style={{ paddingBottom: "12px" }}
                  />
                </Button>
              </Box>
            </Box>
            <Box className="whiteCard">
              <Box className="paddingBox">
                <Box>
                  <img src={supportImage} className="progessImg" />
                </Box>
                <Box className="serviceMidHeading">
                  {this.state.titlethree}
                </Box>
                <Box className="whiteMidPara">{this.state.discripetionthree} </Box>
                <Button
                  variant="text"
                  color="primary"
                  href="https://www.scalecompliance.com/"
                  target="_blank"
                  className="btnstyle"
                >
                  {configJSON.readMoreText}
                  <img
                    src={readMoreArrow}
                    alt="arrow"
                    className="readMoreArrow"
                    style={{ paddingBottom: "12px" }}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </ServiceBox>
    );
  };

  renderContactUs = () => {
    return (
      <ContactBox>
        <Box className="innerContact">
          <Box className="firstContactBox">
            <Box className="contactHeading ">{this.state.titlecontactus}</Box>
            <Box className="contactSecHeading ">
              {this.state.subtitlecontactus}
              <span>
                {" "}
                <img
                  src={readMoreArrow}
                  alt="arrow"
                  className="readMoreArrowTwo"
                />
              </span>
            </Box>
            <Box className="contactPara">{this.state.discripetioncontactus}</Box>
            <Box className="iconList">
              <Box className="icon ">
                <a
                  href="https://x.com/ScaleCompliance"
                  target="_blank"
                  rel="twitter link"
                >
                  <img src={twitterIcon} className="twitterIcon" />
                </a>
              </Box>
              <Box className="icon">
                <a
                  href="https://www.linkedin.com/company/scale-compliance/"
                  target="_blank"
                  rel="linkedIn link"
                >
                  <img src={linkdinImage} className="twitterIcon" />
                </a>
              </Box>
              <Box className="icon">
                <img src={instgramIcon} className="twitterIcon enlar" />
              </Box>
            </Box>
          </Box>
          <SecondContactFieldBox className="secondContactBox">
            <Box className="firstField">
              <TextField
                placeholder="Name"
                name="firstName"
                autoComplete="off"
                className="nameField"
                variant="outlined"
                value={this.state.userName}
                onChange={this.handleFirstName}
                data-test-id="firstNameTestID"
              />
              <TextField
                placeholder="Email"
                name="emailID"
                autoComplete="off"
                className="emailField"
                variant="outlined"
                value={this.state.userEmail}
                onChange={this.handleEmailChange}
                data-test-id="emailInputTestID"
              />
            </Box>
            <Box className="emailErrBox">
              <Typography className="emailError">
                {this.state.emailError ? "please enter valid email" : ""}
              </Typography>
            </Box>
            <Box className="inputContainer">
              <Box className="phoneNumberInputFiled">
                <PhoneInput
                  data-test-id="phoneInput"
                  placeholder="+91 Enter phone number"
                  enableSearch={true}
                  value={this.state.employeeData.dailNumber?.toString()}
                  onChange={this.dailcodeData}
                  inputProps={{
                    required: true,
                    id: "PhoneNumber",
                  }}
                  enableLongNumbers={true}
                  disableSearchIcon={true}
                  jumpCursorToEnd={true}
                  autoFormat={true}
                  inputStyle={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "rgba(0, 31, 0, 1)",
                    color: "#A7A7A7 !important",
                    height: "inherit",
                    opacity: "0.6",
                  }}
                  dropdownStyle={{
                    color: "#A7A7A7 !important",
                    border: "none",
                    background: "rgba(0, 31, 0, 1)",
                  }}
                  containerStyle={{
                    width: "98%",
                    height: "46px",
                    backgroundColor: "rgba(0, 31, 0, 1)",
                    color: "#A7A7A7 !important",
                    border: this.state.phoneNumberStatus
                      ? "1px solid red"
                      : "1px solid #FFFFFF",
                    paddingLeft: "2%",
                  }}
                />
              </Box>
              <Typography className="errorMessage">
                {this.state.phoneNumberStatus}
              </Typography>
            </Box>
            <Box>
              <TextareaAutosize
                maxRows={4}
                autoComplete="off"
                aria-label="maximum height"
                name="messageID"
                placeholder="Message"
                className="messageField"
                value={this.state.userMessage}
                onChange={this.handleMessageChange}
                data-test-id="messageInputTestID"
              />
            </Box>
            <Box className="subBtn">
              <button
                onClick={this.landingPageAPI}
                className="submitButton"
                data-test-id="submitBtnTestID"
              >
                {configJSON.submitText}
                <img src={arrowBlack} alt="button arrow" />
                {this.state.isLoader && (
                  <CircularProgress
                    className="loaderStyle"
                    size={20}
                    thickness={4}
                  />
                )}
              </button>
            </Box>
          </SecondContactFieldBox>
        </Box>
        <Box className="footerBox">
          <Typography className="footerText">
            © {this.getCurrentYear()} Scale KYC, All rights reserved
          </Typography>
          <Typography
            style={{ paddingLeft: "6rem", cursor: "pointer" }}
            className="footerText"
            onClick={this.handleOpenDiaglog}
            data-test-id="privacypolicyid"
          >
            Privacy Policy
            {this.state.open && (
              <PrivacyDialog
                open={this.state.open}
                onClose={this.handleCloseDiaglog}
                disc={this.state.privacyData}
                logo={scaleKYC}
              />
            )}
          </Typography>
        </Box>
      </ContactBox>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <LandingWrapper>
        {this.renderHeader()}
        {this.renderContainers()}
        {this.renderServices()}
        {this.renderContactUs()}
      </LandingWrapper>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const LandingWrapper = styled(Box)({
  overflowX: "hidden",
});
const AboutBox = styled(Box)({
  backgroundColor: "#011e01",
  padding: "60px",
  display: "flex",
  flexDirection: "row",
  "& button": {
    textTransform: "unset",
  },
  "& .readMoreArrowTwo": {
    width: "13px",
    paddingBottom: "12px",
    paddingLeft: "4px",
  },
  "& .aboutFirstBox": {
    width: "50%",
    "& .readMoreArrow": {
      width: "10px",
      height: "10px",
      marginLeft: "6px",
    },
    "& button": {
      color: "#fff",
      paddingLeft: 0,
    },
  },

  "& .aboutHeading": {
    color: "rgba(0, 255, 0, 1)",
    fontSize: "52px",
    fontFamily: "Lato",
    fontWeight: 600,
  },

  "& .aboutSecHeading": {
    color: "#fff",
    fontSize: "36px",
    fontFamily: "Lato",
    fontWeight: 300,
    padding: "16px 0px 16px 0px",
  },

  "& .aboutPara": {
    color: "#fff",
    marginTop: "30px",
    marginBottom: "40px",
    fontSize: "22px",
    fontFamily: "Lato",
    fontWeight: 400,
    textAlign: "justify",
  },

  "& .aboutSecPara": {
    color: "#fff",
    fontSize: "22px",
    fontFamily: "Lato",
    fontWeight: 400,
    textAlign: "justify",
  },

  "& .aboutSecBox": {
    width: "50%",
    display: "flex",
    justifyContent: "center",
  },

  "& .aboutEarthImg": {
    height: "500px",
    objectFit: "cover",
    // transition: "transform 5s, visibility 2s ease-in",
    // filter: "brightness(150%)",
    animation: "continuousTransform 5s infinite linear",
    filter: "brightness(150%)",
    transition: "visibility 2s ease-in",
  },
  // "& img:hover": {
  //   transform: "scale(1.3)"
  // },
  "@keyframes continuousTransform": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.3)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  "@media (max-width: 1200px)": {
    flexDirection: "column",
    "& .aboutFirstBox": {
      width: "100%",
    },
    "& .aboutSecBox": {
      width: "100%",
      textAlign: "center",
    },
    "& .aboutHeading": {
      fontSize: "42px",
    },
    "& .aboutSecHeading": {
      fontSize: "28px",
    },
    "& .aboutPara": {
      fontSize: "18px",
    },
  },

  "@media (max-width: 768px)": {
    padding: "40px",
    "& .aboutHeading": {
      fontSize: "32px",
    },
    "& .aboutSecHeading": {
      fontSize: "24px",
    },
    "& .aboutPara": {
      fontSize: "16px",
    },
    "& .aboutEarthImg": {
      width: "80vw",
      paddingTop: "3rem",
    },
  },

  "@media (max-width: 480px)": {
    padding: "20px",
    "& .aboutHeading": {
      fontSize: "28px",
    },
    "& .aboutSecHeading": {
      fontSize: "20px",
    },
    "& .aboutPara": {
      fontSize: "14px",
    },
  },
});

const ServiceBox = styled(Box)({
  backgroundColor: "#011e01",
  padding: "60px",
  "& button": {
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    textTransform: "unset",
    "& .MuiButton-icon": {
      position: "absolute",
      top: "36px",
      right: "-20px",
    },
  },

  "& .readMoreArrowTwo": {
    width: "13px",
    paddingBottom: "12px",
    paddingLeft: "4px",
  },

  "& .serviceHeading": {
    color: "rgba(0, 255, 0, 1)",
    fontSize: "52px",
    fontFamily: "Lato",
    fontWeight: 600,
  },

  "& .serviceSecHeading": {
    color: "#fff",
    fontSize: "36px",
    fontFamily: "Lato",
    fontWeight: 300,
    padding: "16px 0px 16px 0px",
  },

  "& .serviceSecHeading::first-letter": {
    textTransform: "capitalize !important",
  },

  "& .serviceMidBox": {
    display: "flex",
    gap: "20px",
    marginTop: "30px",
    "& button": {
      color: "rgba(36,36,36,1)",
      paddingLeft: 0,
    },
    "& .readMoreArrow": {
      width: "10px",
      height: "10px",
      marginLeft: "6px",
    },
  },

  "& .firstMidBox": {
    width: "50%",
    backgroundColor: "transparent",
    border: "1px solid rgba(0, 255, 0, 1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  "& .firstMidBox:hover": {
    boxShadow: "0px 0px 16px 4px green",
  },
  "& .paddingBox": {
    padding: "30px 50px",
  },

  "& .progessImg": {
    width: "75px",
    height: "75px",
  },

  "& .serviceMidHeading": {
    color: "#00FF00",
    fontSize: "32px",
    fontFamily: "Lato",
    fontWeight: 900,
    padding: "10px 0px 10px 0px",
  },
  "& .whiteMidPara": {
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: 400,
    textAlign: "justify",
  },
  "& .btnstyle": {
    marginTop: "1rem",
    color: "#242424",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "lato",
    paddingLeft: "0px",
  },

  "& .serviceMidPara": {
    color: "#fff",
    fontFamily: "Lato",
    paddingRight: "40px",
    fontSize: "22px",
    fontWeight: 400,
    textAlign: "justify",
  },

  "& .imgMargin": {
    margin: "40px auto",
  },

  "& .dashboardImg": {
    width: "548px",
    height: "347px",
    borderRadius: "20px",
    objectFit: "contain",
  },

  "& .secMidBox": {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "10px",
  },

  "& .whiteCard": {
    minHeight: "387px",
    backgroundColor: "#fff",
  },
  "& .whiteCard:hover": {
    boxShadow: "0px 0px 16px 4px white",
  },
  "& .whiteMidCard": {
    color: "#000",
  },

  "& .whiteBtn": {
    fontSize: "20px",
    fontFamily: "Lato",
    padding: "10px 0px",
    border: "none",
    backgroundColor: "#fff",
  },
  "@media (max-width: 1200px)": {
    "& .serviceHeading": {
      fontSize: "42px",
    },
    "& .serviceSecHeading": {
      fontSize: "30px",
    },
    "& .serviceMidHeading": {
      fontSize: "28px",
    },
    "& .serviceMidBox": {
      display: "flex",
      flexDirection: "column",
    },
    "& .firstMidBox, & .secMidBox": {
      width: "100%",
      paddingRight: "0px",
    },
    "& contactPara": {
      maxWidth: "100%",
    },
  },

  "@media (max-width: 768px)": {
    padding: "40px",
    "& .serviceHeading": {
      fontSize: "32px",
    },
    "& .serviceSecHeading": {
      fontSize: "24px",
    },
    "& .serviceMidHeading": {
      fontSize: "22px",
    },
    "& .dashboardImg": {
      width: "75vw",
    },
    "& .firstMidBox": {
      paddingRight: "0px",
    },
    "& .serviceMidPara": {
      paddingRight: "0px",
    },
  },

  "@media (max-width: 480px)": {
    padding: "20px",
    "& .serviceHeading": {
      fontSize: "28px",
    },
    "& .serviceSecHeading": {
      fontSize: "20px",
    },
    "& .serviceMidHeading": {
      fontSize: "18px",
    },
    "& .serviceMidBox": {},
  },
});

const MidBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#000 !important",
  width: "100% !important",
  height: "500px",
  position: "relative",
  marginTop: "100px",
  "& .heroLeft": {
    maxWidth: "877px",
    width: "100%",
    position: "relative",
    overflow: " hidden",
    "& .imageAnimate": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      animation: "continuousTransform 5s infinite linear",
      filter: "brightness(150%)",
      transition: "visibility 2s ease-in",
    },
    "@keyframes continuousTransform": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.3)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
    "& .heroArrow": {
      position: "absolute",
      top: "50%",
      left: "30%",
      transform: "translateY(-50%)",
      width: "180px",
      height: "180px",
      transition: "transform .4s",
    },
    "& .heroArrow:hover": {
      transform: "translateY(-50%) rotate(45deg)",
    },
  },
  "& .heroTxt": {
    color: "#fff",
    fontSize: "60px",
    fontWeight: 40,
    lineHeight: "72px",
    position: "absolute",
    right: "60px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  "& .midBackgroundImg": {
    width: "100%",
    height: "500px",
    position: "absolute",
    left: "0px",
    backgroundImage: `url(midBoxDot)`,
    backgroundSize: "cover",
    backgroundColor: "#000",
  },

  "& .midSecBox": {
    width: "100%",
    height: "500px",
    position: "absolute",
    left: "0px",
    zIndex: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  "& .firstMidBox": {
    width: "50%",
    display: "flex",
    justifyContent: "center",
  },

  "& .secondMidBox": {
    width: "50%",
    color: "#FFFF",
    fontSize: "55px",
    fontFamily: "Lato",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "70px",
  },
  "@media (max-width: 480px)": {
    "& .heroTxt": {
      fontSize: "2rem",
      left: "8%",
    },
    "& .heroArrow": {
      width: "5rem",
      height: "5rem",
      left: "12%",
    },
  },
});

const CustomBox = styled(Box)({
  height: "92px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 60px 10px 60px",
  backgroundColor: "#000",
  borderBottom: "0.8px solid #A7A7A7",
  position: "fixed", // Make the header fixed
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000, // Ensure it stays on top
  transform: "translateY(0)",
  transition: "transform 0.3s ease-in-out", // Smooth transition for hiding and showing
  "& .loginBtn": {
    width: "134px",
    height: "44px",
    padding: "0px",
    backgroundColor: "rgba(0, 255, 0, 1)",
    border: "none",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    fontFamily: "lato",
    cursor: "pointer",
  },
  "& .headerLogo": {
    width: "116px",
    heght: "70px",
  },
  "@media (max-width: 1200px)": {
    padding: "10px 40px",
  },

  "@media (max-width: 768px)": {
    padding: "10px 20px",
    "& .loginBtn": {
      width: "100px",
      fontSize: "14px",
    },
    "& .headerLogo": {
      width: "90px",
    },
  },

  "@media (max-width: 480px)": {
    padding: "10px 10px",
    display: "flex",
    width: "95vw",
    "& .loginBtn": {
      width: "90px",
      fontSize: "12px",
    },
    "& .headerLogo": {
      width: "80px",
    },
  },
});
const ContactBox = styled(Box)({
  backgroundColor: "#011e01",
  padding: "60px",
  "& button": {
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    textTransform: "unset",
    "& .MuiButton-icon": {
      position: "absolute",
      top: "36px",
      right: "-20px",
    },
  },
  "& .readMoreArrowTwo": {
    width: "13px",
    paddingBottom: "12px",
    paddingLeft: "4px",
  },

  "& .innerContact": {
    display: "flex",
    borderBottom: "1px solid #fff",
    paddingBottom: "60px",
    marginBottom: "10px",
  },
  "& .footerBox": {
    padding: "32px 0px 0px 0px",
    display: "flex",
  },

  "& .footerText": {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.02em",
    textAlign: "left",
    color: "#A7A7A7",
  },

  "& .firstContactBox": {
    width: "50%",
    paddingRight: "60px",
    boxSizing: "border-box",
  },

  "& .contactHeading": {
    color: "rgba(0, 255, 0, 1)",
    fontSize: "52px",
    fontFamily: "Lato",
    fontWeight: 600,
  },

  "& .contactSecHeading": {
    color: "#fff",
    fontSize: "36px",
    fontFamily: "Lato",
    fontWeight: 300,
    padding: "16px 0px",
  },

  "& .contactPara": {
    color: "#fff",
    margin: "20px 0px",
    fontSize: "22px",
    fontFamily: "Lato",
    fontWeight: 400,
    maxWidth: "481px",
    textAlign: "justify",
  },

  "& .iconList": {
    display: "flex",
    color: "#fff",
  },

  "& .icon": {
    height: "28px",
    width: "28px",
    margin: "10px 30px 0px 0px",
  },
  "& .twitterIcon": {
    height: "28px",
    width: "28px",
    cursor: "pointer",
  },
  "& .enlar": {
    height: "32px",
    width: "32px",
  },
  "& .twitterIcon:hover": {
    border: "1px solid green",
    padding: "4px",
  },

  "& .secondContactBox": {
    outline: "none",
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      color: "#fff",
      fontWeight: 400,
      fontFamily: "lato",
      fontSize: "16px",
    },
    "& input::placeholder": {
      color: "rgba(148, 163, 184, 1)",
    },
  },
  "@media (max-width: 768px)": {
    "& .innerContact": {
      flexDirection: "column",
    },
    "& .firstContactBox, & .secondContactBox": {
      width: "100%",
      paddingTop: " 38px",
      paddingRight: "0px",
    },
    "& .iconList,& .subBtn": {
      justifyContent: "center",
    },
  },
  "@media (max-width: 480px)": {
    padding: "20px",
  },
});

const SecondContactFieldBox = styled(Box)({
  width: "50%",

  "& .firstField": {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
    outline: "none",
  },

  "& .nameField": {
    width: "50%",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff",
  },

  "& .nameField .MuiInputBase-formControl": {
    color: "#fff",
  },
  "& .MuiInputBase-formControl": {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "lato",
    fontWeight: 400,
  },

  "&:hover .nameField .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff",
  },

  "& .emailField": {
    width: "50%",
  },
  "& .emailError": {
    color: "red",
  },
  "& .emailErrBox": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },

  "& .emailField .MuiInputBase-formControl": {
    color: "#fff",
  },

  "&:hover .emailField .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff",
  },

  "& .phoneField": {
    width: "100%",
  },

  "& .phoneField .MuiOutlinedInput-root": {
    color: "#fff",
  },

  "&:hover .phoneField .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff",
  },

  "& .messageField": {
    width: "100%",
    height: "120px !important",
    margin: "20px 0px",
    padding: "10px",
    backgroundColor: "transparent",
    borderColor: "#fff",
    borderRadius: "4px",
    color: "#fff",
    resize: "none",
    fontFamily: "lato",
    fontSize: "16px",
    fontWeight: 400,
    "&:focus-visible": {
      outline: "none",
    },
  },

  "& .subBtn": {
    display: "flex",
    justifyContent: "flex-end",
  },

  "& .submitButton": {
    width: "180px",
    height: "50px",
    backgroundColor: "rgba(0, 255, 0, 1)",
    cursor: "pointer",
    border: "none",
    fontSize: "18px",
    fontFamily: "Lato",
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    "& img": {
      width: "24px",
      height: "24px",
      marginLeft: "-6px",
    },
  },

  "& .submitButton:hover": {
    backgroundColor: "#57fa48",
  },
  "& .inputContainer": {
    display: "flex",
    flexDirection: "column",
    marginTop: "3%",
  },
  "& .lableName": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#D3D3D3",
    fontFamily: "Lato",
    lineHeight: "22px",
    textAlign: "left",
    paddingBottom: "4px",
  },
  "&. phoneNumberInputFiled": {
    display: "flex",
  },
  "& .react-tel-input": {
    height: "46px",
    width: "30%",
    color: "#A7A7A7 !important",
  },
  "& .react-tel-input .form-control": {
    width: "74%",
    backgroundColor: "rgba(0, 31, 0, 1) !important",
    paddingLeft: "28px",
    color: "#A7A7A7 !important",
  },
  "& .react-tel-input .selected-flag": {
    width: "0px",
    borderRadius: "0px",
    padding: "0px",
    color: "#A7A7A7 !important",
  },

  "& .react-tel-input .country-list": {
    margin: "-250px 0 0px -10px",
    boxShadow: "1px 2px 10px rgba(0, 0, 0, .35)",
    zIndex: 37,
    backgroundColor: "#2f2f2f !important",
    width: "377px",
    borderRadius: "7px",
    color: "#A7A7A7 !important",
  },
  "& .react-tel-input .flag-dropdown": {
    border: "none",
    background: "none",
    color: "#A7A7A7 !important",
  },
  "& .flag-dropdown::hover": {
    border: "none",
    background: "none",
    color: "#A7A7A7 !important",
  },
  "& .errorMessage": {
    color: "red",
  },
});

// Customizable Area End
